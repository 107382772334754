@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.name {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.firstNameRoot {
  width: calc(34% - 9px);
}

.lastNameRoot {
  width: calc(66% - 9px);
}

.password {
  composes: marketplaceModalPasswordMargins from global;
  margin-top: 16px;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
}

.radioBtnInput {
  /* margin-bottom: 8px; */
  border: 1px solid var(--marketplaceColor);
  display: block;
  padding: 0 10px;
  border-radius: 3px;
  cursor: pointer;

  & label {
    padding: 4px;
    cursor: pointer;
  }
}

.modalCloseBtnHide {
  &>div {
    &>div {
      &>button {
        display: none !important;
      }
    }
  }
}

.modalTextCenter {
  text-align: center;
  color: #000 !important;
  font-size: 30px;
  line-height: 40px;
  margin: 0;
}

.signupWrapper {
  display: flex;
  justify-content: space-between;
  padding: 60px 20px;
  max-width: 1200px;
  margin: 0 auto;
  gap: 25px;

  @media (max-width: 1200px) {
    max-width: 100vw;
    gap: 10px;
  }

  @media (max-width: 767px) {
    padding: 20px 20px 40px;
    flex-direction: column-reverse;
  }

  & .leftTextSection {
    width: 50%;

    @media (max-width: 1023px) {
      width: 60%;
    }

    @media (max-width: 767px) {
      width: 100%;
    }
  }

  .signupText {
    @media (max-width: 767px) {
      margin-top: 10px;
    }

    & span {
      font-size: 36px;
      line-height: 1.2;
      letter-spacing: -0.5px;
      font-weight: 800;
      text-wrap: balance;

      @media (max-width: 1200px) {
        font-size: 24px;
        font-weight: 600;
        letter-spacing: inherit;
        line-height: 30px;
      }
    }

    & p {
      font-size: 1.15rem;
      line-height: 1.55rem;
      letter-spacing: -0.031rem;

      @media (max-width: 767px) {
        font-size: 14px;
      }
    }
  }

  & .searchSection {
    display: flex;
    align-items: flex-start;
    gap: 20px;

    @media (max-width: 576px) {
      flex-direction: column;
    }

    &>div {
      width: 100%;
    }

    /* & button {
      width: 250px;
    } */
    &>button {
      min-height: 61px;

      @media (max-width:767px) {
        min-height: 48px;
      }
    }
  }

  & .servicesHeading {
    margin-top: 16px;
    color: rgb(59 65 109 / var(--tw-text-opacity));
    margin-bottom: 1rem;
  }
}

.qualitySectionContent {
  max-width: 1200px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-top: 60px;
  margin: 0 auto;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
  }

  & ul {
    padding-left: 30px;
    min-height: 190px;

    @media (max-width:767px) {
      padding-left: 19px;
      min-height: 80px;
    }
  }

  &>div {
    background-color: #fff;
    border: 1px solid #d1dae1;
    box-shadow: 0 1px 2px 0 #d1dae1;
    padding: 12px 20px;

    & a {
      text-align: center;
    }
  }
}

.joinThemSection {
  padding: 60px 22px;

  & .sectionContent {
    max-width: 1200px;
    margin: 0 auto;

    & h4 {
      font-size: 24px;
      font-weight: 600;
      color: #000;

      @media (max-width: 767px) {
        font-size: 24px;
        font-weight: 600;
      }
    }

    & h5 {
      font-size: 16px;
      line-height: 1.75rem;
      letter-spacing: -0.031rem;
      margin-bottom: 20px;

      @media (max-width: 767px) {
        font-size: 14px;
      }
    }
  }

  & button {
    width: max-content;
    padding: 0 22px;
  }
}

.qualitySection {
  background: #fff;
  padding-top: 60px;
  padding-bottom: 60px;
  padding-left: 30px;
  padding-right: 30px;
  max-width: 100vw;

  @media (max-width: 767px) {
    padding: 30px 22px;
  }

  & h5 {
    font-size: 1.25rem;
    line-height: 1.75rem;
    letter-spacing: -0.031rem;
    color: #000;

    @media (max-width: 767px) {
      font-size: 24px;
      font-weight: 600;
    }
  }

  & ul {
    & li {
      list-style-type: disc;
      font-size: 13px;
    }
  }
}

.rightImageSection {
  flex: 1;

  & img {
    height: 400px;
    width: 100%;
    object-fit: cover;

    @media (max-width: 1023px) {
      height: auto;
      min-height: 280px;
    }

    @media (max-width: 767px) {
      width: 100%;
      height: auto;
      max-width: 100%;
    }
  }

  /* @media (max-width: 767px) {
    display: none;
  } */
}

.locationInputWrapper {
  display: flex;
  align-items: flex-start;
  gap: 10px;

  @media (max-width: 767px) {
    flex-direction: column;
  }

  & p {
    padding: 0;
  }

  & select {
    min-width: 180px;
  }
}

.buttonGroup {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 30px;
  flex-wrap: wrap;

  & button {
    width: max-content;
    padding: 0 16px;

    @media (max-width: 460px) {
      width: 100%;
    }
  }

  & svg {
    & path {
      fill: #000;
    }
  }
}

.actionButtons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin-top: 10px;

  & button {
    width: 100px;
  }
}

.termsAndConditions {
  margin-top: 40px;
  display: flex;
  align-items: center;
  /* justify-content: center; */
}

.radioBoxWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  & label {
    margin-bottom: 0;
  }
}

.inputBox {
  height: 100%;

  & select {
    height: 100%;
  }

  & input {
    &:focus {
      box-shadow: none !important;
    }
  }
}

.stepsModal {
  & .modalTitle {
    color: #000;
  }
}

.subtitle {
  text-align: left;
  color: #000 !important;
}

.subtitleCenter {
  text-align: left;
  color: #000 !important;
  text-align: center;
}

.getStartedBtn {
  width: max-content;
  min-width: 150px;

  @media (max-width: 767px) {
    width: 100%;
  }
}

.signUpLeads {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
  padding: 20px;
  background-color: #eaf1fc;

  @media (max-width: 767px) {
    flex-direction: column;

    &>div {
      width: 100%;
    }

    & a {
      margin-top: 20px;
      width: 100%;
    }
  }

  & h4 {
    margin: 0;
  }

  & p {
    margin: 0;
  }

  & button {
    padding: 0 20px;
  }
}

.locationAddressForm {
  &>div:first-child {
    background-color: #fff;
    border-radius: 6px;
    border: 1px solid #d8dce6;
    padding-left: 10px;

    & input {
      border: 0;
      padding: 8px 16px;

      &:focus {
        outline: unset !important;
        border: 0 !important;
        box-shadow: none;
      }
    }
  }
}

.venderError {
  color: #ff0000;
  font-size: 14px;
  font-weight: 600;
}

.optInputWrapper {
  display: flex;
  gap: 15px;
  margin-bottom: 2em;

  & input {
    text-align: center;
  }
}

.phoneWrapper {
  margin-top: 16px;

  & .phoneLabel {
    @media(--viewportLarge) {
      font-weight: var(--fontWeightSemiBold);
      line-height: 16px;
      margin-top: 0;
      font-size: 13px;
      margin-bottom: 0;
      padding-top: 3px;
      padding-bottom: 13px;
    }
  }

  & .phoneBox {
    & input {
      height: 52px !important;
      width: 100% !important;

      @media (max-width:768px) {
        height: 40px !important;
      }
    }
  }
}

.validateText {
  color: #ff0000;
  font-size: 16px;
  font-weight: 400;
  margin-top: 10px;
}

.bodyContent {
  & .otpVerification {
    text-align: center;

    & span {
      font-size: 26px;
      font-weight: 700;
    }
  }

  & .labelOtp {
    font-size: 15px;
    margin-top: 24px;
  }

}

.optTimer {
  display: flex;
  gap: 50px;
  align-items: center;
  justify-content: space-between;

  @media(max-width: 500px) {
    flex-direction: column;
    flex-wrap: wrap;
    gap: 20px;
  }

  & .secondRemain {
    color: #000;
    /* padding-left: 30px; */
  }
}

.submitOtp {
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;

  @media (max-width:768px) {
    min-height: 36px;
  }
}

.otpText {
  /* text-align: center;
  color: #ff0000;
  font-size: 16px; */
}

.otpVerified {
  color: green;
  font-size: 16px;
  text-align: center;
}

.invalidOtp {
  color: red;
  font-size: 16px;
  text-align: center;
}